export const businessCategoryOptions = [
  {
    id: 0,
    label: 'All Categories',
    value: 'all',
    icon: 'all',
  },
  {
    id: 5001,
    label: 'Automotive',
    value: 'auto',
    icon: 'auto_car',
  },
  {
    id: 5002,
    label: 'Beauty and Wellness',
    value: 'beauty',
    icon: 'beauticians',
  },
  {
    id: 5003,
    label: 'Business Services',
    value: 'business',
    icon: 'business',
  },
  {
    id: 5004,
    label: 'Education',
    value: 'education',
    icon: 'education',
  },
  {
    id: 5005,
    label: 'Events and Entertainment',
    value: 'events',
    icon: 'dance',
  },
  {
    id: 5006,
    label: 'Financial',
    value: 'finance',
    icon: 'financial',
  },
  {
    id: 5007,
    label: 'Food and Dining',
    value: 'food',
    icon: 'restaurants',
  },
  {
    id: 5008,
    label: 'Health and Medical',
    value: 'health',
    icon: 'medical',
  },
  {
    id: 5009,
    label: 'Home and Garden',
    value: 'home',
    icon: 'home_garden',
  },
  {
    id: 5010,
    label: 'Manufacturing and Industrial',
    value: 'manufacturing',
    icon: 'engineers',
  },
  {
    id: 5011,
    label: 'Personal Services',
    value: 'personal',
    icon: 'personal',
  },
  {
    id: 5012,
    label: 'Professional Services',
    value: 'professional',
    icon: 'professional',
  },
  {
    id: 5013,
    label: 'Property and Real Estate',
    value: 'property',
    icon: 'property',
  },
  {
    id: 5014,
    label: 'Shopping and Retail',
    value: 'shopping',
    icon: 'boutiques',
  },
  {
    id: 5015,
    label: 'Sports and Recreation',
    value: 'sports',
    icon: 'sports',
  },
  {
    id: 5016,
    label: 'Tourism and Travel',
    value: 'tourism',
    icon: 'travel',
  },
  {
    id: 5017,
    label: 'Transportation',
    value: 'transportation',
    icon: 'trucking',
  },
];

export const getIdForCategoryFromCode = (code: string): number => {
  const category = businessCategoryOptions.find(
    (category) => category.value === code
  );
  return category ? category.id : 0;
};

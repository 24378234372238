import { RootState } from './store';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }

    const savedState = JSON.parse(serializedState);
    if (!savedState.vendors.searchStage)
      savedState.vendors.searchStage = 'location';

    return savedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: RootState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.removeItem('state');
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};

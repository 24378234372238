import type { AppThunk, RootState } from '../store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import languageList from '../../utils/language-list';

export interface LanguageWithNative {
  code: string;
  englishName: string;
  nativeName: string;
}
export interface LanguagesState {
  selectedLanguageCode?: string;
  languages: LanguageWithNative[];
}

export const languagesInitialState: LanguagesState = {
  languages: [],
};

export const languagesSlice = createSlice({
  name: 'languages',
  initialState: languagesInitialState,
  reducers: {
    updateLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguageCode = action.payload;
    },
    updateLanguages: (state, action: PayloadAction<LanguageWithNative[]>) => {
      state.languages = action.payload;
    },
  },
});

export const getLanguages = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updateLanguages(languageList));
  } catch (e) {
    console.log(e);
  }
};

export const { updateLanguage, updateLanguages } = languagesSlice.actions;

export const languagesState = (state: RootState): LanguagesState =>
  state.languages;

export default languagesSlice.reducer;

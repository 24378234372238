import type { AppThunk, RootState } from '../store';
import {
  Conversation,
  ConversationSearchInput,
  CreateMessageInput,
  LogConversationVisitInput,
  SpecificConversationInput,
} from '../../generated/apolloComponents';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createMessageMutation,
  getConversationBetweenUserAndVendorQuery,
  getConversationsQuery,
  logConversationVisitMutation,
} from '../../graphql/conversations';

import clientCreator from '../../services/apolloClient';

export interface ConversationsState {
  current?: Conversation;
  conversations: Conversation[];
}

export const conversationsInitialState: ConversationsState = {
  conversations: [],
};

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: conversationsInitialState,
  reducers: {
    updateConversation: (state, action: PayloadAction<Conversation>) => {
      state.current = action.payload;

      state.conversations = state.conversations.map((c) => {
        if (c.id === action.payload.id) {
          return action.payload;
        }
        return c;
      });
    },
    updateConversations: (state, action: PayloadAction<Conversation[]>) => {
      state.conversations = action.payload;
      if (state.current) {
        state.current = state.conversations.find(
          (c) => c.id === state.current?.id
        );
      }
    },
  },
});

export const getConversations =
  (searchParams: ConversationSearchInput): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getConversationsQuery,
        variables: {
          searchParams,
        },
        fetchPolicy: 'network-only',
      });

      dispatch(
        updateConversations(
          response.data.getConversations.filter(
            (c: Conversation) => c.messages.length > 0
          )
        )
      );
    } catch (e) {
      console.log(e);
    }
  };

export const getConversationBetweenUserAndVendor =
  (searchParams: SpecificConversationInput): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getConversationBetweenUserAndVendorQuery,
        variables: {
          searchParams,
        },
        fetchPolicy: 'network-only',
      });
      dispatch(
        updateConversation(response.data.getConversationBetweenUserAndVendor)
      );
    } catch (e) {
      console.log(e);
    }
  };

export const createMessage =
  (messageData: CreateMessageInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { current: currentConversation } = getState().conversations;
      const user_uid = currentConversation?.user.uid;
      const vendor_id = currentConversation?.vendor.id;

      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: createMessageMutation,
        variables: {
          messageData,
        },
      });
      dispatch(getConversationBetweenUserAndVendor({ user_uid, vendor_id }));
    } catch (e) {
      console.log(e);
    }
  };

export const logConversationVisit =
  (visitData: LogConversationVisitInput, user_uid: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: logConversationVisitMutation,
        variables: {
          visitData,
        },
      });
      dispatch(getConversations({ user_uid }));
    } catch (e) {
      console.log(e);
    }
  };

export const { updateConversation, updateConversations } =
  conversationsSlice.actions;

export const conversationsState = (state: RootState): ConversationsState =>
  state.conversations;

export default conversationsSlice.reducer;

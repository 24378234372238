const namesOfLanguages = [
  { code: 'am', englishName: 'Amharic', nativeName: 'አማርኛ' },
  { code: 'ar', englishName: 'Arabic', nativeName: 'العربية' },
  { code: 'bn', englishName: 'Bengali', nativeName: 'বাংলা' },
  { code: 'my', englishName: 'Burmese', nativeName: 'ဗမာစာ' },
  { code: 'zh', englishName: 'Chinese', nativeName: '汉语' },
  { code: 'en', englishName: 'English', nativeName: 'English' },
  { code: 'fr', englishName: 'French', nativeName: 'français' },
  { code: 'hi', englishName: 'Hindi', nativeName: 'हिन्दी' },
  { code: 'ht', englishName: 'Haitian Creole', nativeName: 'Kreyòl ayisyen' },
  { code: 'ko', englishName: 'Korean', nativeName: '한국어' },
  { code: 'pl', englishName: 'Polish', nativeName: 'polszczyzna' },
  { code: 'pt', englishName: 'Portuguese', nativeName: 'Português' },
  { code: 'es', englishName: 'Spanish', nativeName: 'Español' },
  { code: 'tl', englishName: 'Tagalog/Filipino', nativeName: 'Wikang Tagalog' },
  { code: 'tr', englishName: 'Turkish', nativeName: 'Türkçe' },
  { code: 'uk', englishName: 'Ukrainian', nativeName: 'Українська' },
  { code: 'ur', englishName: 'Urdu', nativeName: 'اردو' },
  { code: 'vi', englishName: 'Vietnamese', nativeName: 'Tiếng Việt' },
];

export default namesOfLanguages;

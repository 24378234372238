import 'firebase/analytics';
import 'firebase/auth';

import firebase from 'firebase/app';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDWuxRVZj870lArxy514X7qGi4fLIVD0Gs',
  authDomain: 'myecen-507cc.firebaseapp.com',
  projectId: 'myecen-507cc',
  storageBucket: 'myecen-507cc.appspot.com',
  messagingSenderId: '895760863255',
  appId: '1:895760863255:web:2a4c28a1b45f942a9e0c52',
  measurementId: 'G-Z8X5XCL708',
};

const firebaseClient = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_CONFIG);
    firebase.analytics();
  }
};

export default firebaseClient;

import { gql } from '@apollo/client';

export const getConversationBetweenUserAndVendorQuery = gql`
  query getConversationBetweenUserAndVendor(
    $searchParams: SpecificConversationInput!
  ) {
    getConversationBetweenUserAndVendor(searchParams: $searchParams) {
      id
      vendor {
        id
        business_name
      }
      user {
        uid
        display_name
      }
      messages {
        sender_type
        text
        messageDate
      }
    }
  }
`;

export const getConversationsQuery = gql`
  query getConversations($searchParams: ConversationSearchInput!) {
    getConversations(searchParams: $searchParams) {
      id
      updatedAt
      vendor {
        id
        business_name
      }
      user {
        uid
        display_name
      }
      messages {
        id
        sender_type
        text
        messageDate
      }
      lastUserVisit
      lastVendorVisit
    }
  }
`;

export const createMessageMutation = gql`
  mutation createMessage($messageData: CreateMessageInput!) {
    createMessage(messageData: $messageData) {
      id
    }
  }
`;

export const logConversationVisitMutation = gql`
  mutation logConversationVisit($visitData: LogConversationVisitInput!) {
    logConversationVisit(visitData: $visitData)
  }
`;

import { gql } from '@apollo/client';

export const allCountries = gql`
  query allCountries {
    allCountries {
      id
      country_code
      country_name
      nationality
    }
  }
`;

import { gql } from '@apollo/client';

export const allCategoriesQuery = gql`
  query allCategories {
    allCategories {
      category_name
      id
      keywords
      icon
    }
  }
`;

import './i18n/config';

import React, { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import { clearCurrentUser } from './store/slices/usersSlice';
import firebase from 'firebase/app';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// const CancelSubscription = React.lazy(
//   () => import('./components/pages/CancelSubscription')
// );
// const PayPalPayment = React.lazy(
//   () => import('./components/pages/PayPalPayment')
// );
// const PaymentInstrument = React.lazy(
//   () => import('./components/pages/PaymentInstrument')
// );
// const PaymentPlanPage = React.lazy(
//   () => import('./components/pages/PaymentPlanPage')
// );
// const StripePayment = React.lazy(
//   () => import('./components/pages/StripePayment')
// );

const Welcome = React.lazy(() => import('./components/pages/Welcome'));
const Language = React.lazy(() => import('./components/pages/Language'));
const Country = React.lazy(() => import('./components/pages/Country'));
const StartSearch = React.lazy(() => import('./components/pages/StartSearch'));
const SearchResults = React.lazy(
  () => import('./components/pages/SearchResults')
);
const VendorDetails = React.lazy(
  () => import('./components/pages/VendorDetails')
);
const Favorites = React.lazy(() => import('./components/pages/Favorites'));
const SignUp = React.lazy(() => import('./components/pages/SignUp'));
const SignIn = React.lazy(() => import('./components/pages/SignIn'));
const ListService = React.lazy(() => import('./components/pages/ListService'));
const Profile = React.lazy(() => import('./components/pages/Profile'));
const Messages = React.lazy(() => import('./components/pages/Messages'));
const ConversationDetails = React.lazy(
  () => import('./components/pages/ConversationDetails')
);
const TermsService = React.lazy(
  () => import('./components/pages/TermsService')
);
const PrivacyPolicy = React.lazy(
  () => import('./components/pages/PrivacyPolicy')
);
const FAQs = React.lazy(() => import('./components/pages/FAQs'));
const Contact = React.lazy(() => import('./components/pages/Contact'));
const About = React.lazy(() => import('./components/pages/About'));
const Share = React.lazy(() => import('./components/pages/Share'));
const AddReview = React.lazy(() => import('./components/pages/AddReview'));
const ForgotPassword = React.lazy(
  () => import('./components/pages/ForgotPassword')
);
const RegistrationRequired = React.lazy(
  () => import('./components/pages/RegistrationRequired')
);
const AfterVendorPageCreation = React.lazy(
  () => import('./components/pages/AfterVendorPageCreation')
);

const SignOut = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().signOut();
    dispatch(clearCurrentUser());
    history.push('/');
  }, [dispatch, history]);

  return null;
};

const MainApp = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n]);

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Welcome} />
        <Route exact path="/select-language" component={Language} />
        <Route exact path="/select-country" component={Country} />
        <Route exact path="/start-search" component={StartSearch} />
        <Route exact path="/search-results" component={SearchResults} />
        <Route exact path="/vendor-details/:id" component={VendorDetails} />
        <Route exact path="/favorites" component={Favorites} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/sign-out" component={SignOut} />
        <Route exact path="/list-service" component={ListService} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/messages" component={Messages} />
        <Route
          exact
          path="/conversation-details"
          component={ConversationDetails}
        />
        <Route exact path="/terms-service" component={TermsService} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/faqs" component={FAQs} />
        <Route exact path="/contact-us" component={Contact} />
        <Route exact path="/about" component={About} />
        <Route exact path="/share-ecen" component={Share} />
        <Route
          exact
          path="/registration-required"
          component={RegistrationRequired}
        />
        <Route exact path="/add-review" component={AddReview} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/new-vendor-congrats"
          component={AfterVendorPageCreation}
        />
      </Switch>
    </Router>
  );
};

export default MainApp;

import { Action, Store, configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStorage';

import { ThunkAction } from 'redux-thunk';
import categoriesReducer from './slices/categoriesSlice';
import { combineReducers } from 'redux';
import conversationsReducer from './slices/conversationsSlice';
import countriesReducer from './slices/countriesSlice';
import languagesReducer from './slices/languagesSlice';
import navigationReducer from './slices/navigationSlice';
import throttle from 'lodash/throttle';
import usersReducer from './slices/usersSlice';
import vendorsReducer from './slices/vendorsSlice';

const rootReducer = combineReducers({
  countries: countriesReducer,
  languages: languagesReducer,
  navigation: navigationReducer,
  users: usersReducer,
  vendors: vendorsReducer,
  categories: categoriesReducer,
  conversations: conversationsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<any>>;

const preloadedState = loadState();

const store: Store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

store.subscribe(
  throttle(() => {
    const minVendorToSave = {
      ...store.getState().vendors,
      matchingVendors: [],
    };

    delete minVendorToSave.current;

    const minConversationsToSave = {
      ...store.getState().conversations,
      current: null,
      conversations: [],
    };

    saveState({
      countries: store.getState().countries,
      languages: store.getState().languages,
      navigation: store.getState().navigation,
      users: store.getState().users,
      vendors: minVendorToSave,
      categories: store.getState().categories,
      conversations: minConversationsToSave,
    });
  }, 3000)
);

export default store;

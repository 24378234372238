const searchBoundingBox = (searchRadius: number, lat: number, lng: number) => {
  const angularDistance = searchRadius / 6371;
  const latStartInRads = (lat / 180) * Math.PI;
  const lngStartInRads = (lng / 180) * Math.PI;
  const northBearing = 0;
  const southBearing = Math.PI;
  const eastBearing = Math.PI / 2;
  const westBearing = (Math.PI * 3) / 2;

  const maxLatRadians = Math.asin(
    Math.sin(latStartInRads) * Math.cos(angularDistance) +
      Math.cos(latStartInRads) *
        Math.sin(angularDistance) *
        Math.cos(northBearing)
  );

  const maxLat = maxLatRadians * (180 / Math.PI);

  const minLat =
    Math.asin(
      Math.sin(latStartInRads) * Math.cos(angularDistance) +
        Math.cos(latStartInRads) *
          Math.sin(angularDistance) *
          Math.cos(southBearing)
    ) *
    (180 / Math.PI);

  const maxLng =
    (lngStartInRads +
      Math.atan2(
        Math.sin(eastBearing) *
          Math.sin(angularDistance) *
          Math.cos(latStartInRads),
        Math.cos(angularDistance) -
          Math.sin(latStartInRads) * Math.sin(maxLatRadians)
      )) *
    (180 / Math.PI);

  const minLng =
    (lngStartInRads +
      Math.atan2(
        Math.sin(westBearing) *
          Math.sin(angularDistance) *
          Math.cos(latStartInRads),
        Math.cos(angularDistance) -
          Math.sin(latStartInRads) * Math.sin(maxLatRadians)
      )) *
    (180 / Math.PI);

  return {
    minLat,
    maxLat,
    minLng,
    maxLng,
  };
};

export default searchBoundingBox;

import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { CSSReset, CircularProgress } from '@chakra-ui/react';
import React, { Suspense } from 'react';

import { AnimatePresence } from 'framer-motion';
import { AuthProvider } from './authenticate/auth';
import MainApp from './MainApp';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import store from './store/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CSSReset />
      <AuthProvider>
        <AnimatePresence exitBeforeEnter>
          <div
            style={{
              margin: 'auto',
              minHeight: '100vh',
            }}
          >
            <Suspense
              fallback={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    height: '100vh',
                  }}
                >
                  <CircularProgress isIndeterminate color="#0156D9" />
                </div>
              }
            >
              <MainApp />
            </Suspense>
          </div>
        </AnimatePresence>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

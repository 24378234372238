import type { AppThunk, RootState } from '../store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Category } from '../../generated/apolloComponents';
import { allCategoriesQuery } from '../../graphql/categories';
import clientCreator from '../../services/apolloClient';

export interface CategoriesState {
  categories: Category[];
  selectedCategory?: Category;
}

export const categoriesInitialState: CategoriesState = {
  categories: [],
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: categoriesInitialState,
  reducers: {
    updateCategory: (state, action: PayloadAction<Category>) => {
      state.selectedCategory = action.payload;
    },
    updateCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
  },
});

export const getCategories = (): AppThunk => async (dispatch) => {
  try {
    const gqlClient = clientCreator();
    const response = await gqlClient.query({ query: allCategoriesQuery });
    dispatch(updateCategories(response.data.allCategories));
  } catch (e) {
    console.log(e);
  }
};

export const { updateCategory, updateCategories } = categoriesSlice.actions;

export const categoriesState = (state: RootState): CategoriesState =>
  state.categories;

export default categoriesSlice.reducer;

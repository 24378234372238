import { gql } from '@apollo/client';

export const getVendorDetailsQuery = gql`
  query getVendorDetails($id: String!) {
    getVendorDetails(id: $id) {
      id
      business_name
      business_description
      image_url
      country {
        country_code
        country_name
      }
      contact_email
      contact_phone
      website
      instagram
      allow_email
      allow_messages
      number_reviews
      average_rating
      street_address
      latitude
      longitude
      reviews {
        id
        text
        stars
        createdAt
        user {
          display_name
        }
      }
      sub_category
      service_1
      service_2
      service_3
      service_4
      category {
        category_name
      }
      languages {
        code
        english_name
      }
    }
  }
`;

export const getVendorsMatchingSearchAndLocationQuery = gql`
  query getVendorsMatchingSearchAndLocation(
    $searchParams: VendorSearchTextAndLocationInput!
  ) {
    getVendorsMatchingSearchAndLocation(searchParams: $searchParams) {
      id
      business_name
      business_description
      image_url
      contact_email
      contact_phone
      website
      instagram
      allow_email
      allow_messages
      number_reviews
      average_rating
      street_address
      latitude
      longitude
      category {
        category_name
      }
      country {
        country_code
        country_name
      }
    }
  }
`;

export const getVendorsMatchingCategoryAndLocationQuery = gql`
  query getVendorsMatchingCategoryAndLocation(
    $searchParams: VendorCategoryAndLocationInput!
  ) {
    getVendorsMatchingCategoryAndLocation(searchParams: $searchParams) {
      id
      business_name
      business_description
      image_url
      contact_email
      contact_phone
      website
      instagram
      allow_email
      allow_messages
      number_reviews
      average_rating
      street_address
      latitude
      longitude
      sub_category
      service_1
      service_2
      service_3
      service_4
      category {
        category_name
      }
      country {
        country_code
        country_name
      }
    }
  }
`;

export const getVendorsInLocationQuery = gql`
  query getVendorsInLocation($searchParams: VendorsInLocationInput!) {
    getVendorsInLocation(searchParams: $searchParams) {
      id
      business_name
      business_description
      image_url
      contact_email
      contact_phone
      website
      instagram
      allow_email
      allow_messages
      number_reviews
      average_rating
      street_address
      latitude
      longitude
      sub_category
      service_1
      service_2
      service_3
      service_4
      category {
        category_name
      }
      country {
        country_code
        country_name
      }
    }
  }
`;

export const updateVendorCategoryMutation = gql`
  mutation updateVendorCategory($categoryId: String!) {
    updateVendorCategory(categoryId: $categoryId)
  }
`;

export const updateVendorNameAndDescriptionMutation = gql`
  mutation updateVendorNameAndDescription(
    $data: UpdateVendorNameAndDescriptionInput!
  ) {
    updateVendorNameAndDescription(data: $data)
  }
`;

export const updateVendorLocationMutation = gql`
  mutation updateVendorLocation($data: UpdateVendorLocationInput!) {
    updateVendorLocation(data: $data)
  }
`;

export const updateVendorLanguagesMutation = gql`
  mutation updateVendorLanguages($data: UpdateVendorLanguagesInput!) {
    updateVendorLanguages(data: $data)
  }
`;

export const updateVendorCountryMutation = gql`
  mutation updateVendorCountry($data: UpdateVendorCountryInput!) {
    updateVendorCountry(data: $data)
  }
`;

export const updateVendorImageMutation = gql`
  mutation updateVendorImage($data: UpdateVendorImageInput!) {
    updateVendorImage(data: $data)
  }
`;

export const createVendorMutation = gql`
  mutation createVendor($data: CreateVendorInput!) {
    createVendor(data: $data)
  }
`;

export const addVisitMutation = gql`
  mutation addVisit($data: VisitInput!) {
    addVisit(data: $data)
  }
`;

export const getAvailableCategoriesQuery = gql`
  query getAvailableCategories($searchParams: AvailableCategoriesInput!) {
    getAvailableCategories(searchParams: $searchParams) {
      id
      business_name
      business_description
      image_url
      contact_email
      contact_phone
      website
      instagram
      allow_email
      allow_messages
      number_reviews
      average_rating
      street_address
      latitude
      longitude
      category {
        id
        category_name
      }
      country {
        country_code
        country_name
      }
    }
  }
`;

import type { AppThunk, RootState } from '../store';
import {
  LikeInput,
  ReviewInput,
  SendEmailInput,
  SendEmailToVendorInput,
  UpdateVendorCountryInput,
  UpdateVendorImageInput,
  UpdateVendorLanguagesInput,
  UpdateVendorLocationInput,
  UpdateVendorNameAndDescriptionInput,
  User,
  UserInput,
  Vendor,
  WelcomeEmailInput,
} from '../../generated/apolloComponents';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  VendorWithSearchString,
  generateSearchStringForVendor,
  getVendorDetails,
} from './vendorsSlice';
import {
  addLikeMutation,
  createReviewMutation,
  createUser,
  getFavoritesQuery,
  getUserByIdQuery,
  removeLikeMutation,
  sendEmailMutation,
  sendEmailToVendorMutation,
  sendWelcomeEmailMutation,
  updatePersonalProfileMutation,
  uploadVendorImageMutation,
  userExistsQuery,
} from '../../graphql/users';
import {
  createVendorMutation,
  updateVendorCategoryMutation,
  updateVendorCountryMutation,
  updateVendorImageMutation,
  updateVendorLanguagesMutation,
  updateVendorLocationMutation,
  updateVendorNameAndDescriptionMutation,
} from '../../graphql/vendors';

import clientCreator from '../../services/apolloClient';
import uploadClientCreator from '../../services/apolloUploadClient';

export type PaymentFrequency = 'monthly' | 'annually';

export interface VendorInProgress {
  image_url?: string;
  business_name?: string;
  business_description?: string;
  categoryId?: string;
  countryCode?: string;
  sub_category?: string;
  latitude?: number;
  longitude?: number;
  street_address?: string;
  service_1?: string;
  service_2?: string;
  service_3?: string;
  service_4?: string;
  contact_phone?: string;
  contact_email?: string;
  website?: string;
  instagram?: string;
}
export interface UsersState {
  current?: User;
  favorites: VendorWithSearchString[];
  userLocation?: {
    lat: number;
    lng: number;
  };
  resultsView: string;
  useUserLocation: boolean;
  vendorInProgress?: VendorInProgress;
}

export const usersInitialState: UsersState = {
  favorites: [],
  useUserLocation: false,
  resultsView: 'list',
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    updateUser: (state, action: PayloadAction<User>) => {
      state.current = action.payload;
    },
    signOut: (state) => {
      state.current = undefined;
      state.favorites = [];
    },
    updateFavorites: (state, action: PayloadAction<Vendor[]>) => {
      const vendorsWithSearchString = action.payload.map((vendor) => {
        return {
          ...vendor,
          searchString: generateSearchStringForVendor(vendor),
        };
      });

      state.favorites = vendorsWithSearchString;
    },
    clearCurrentUser: (state) => {
      state.current = undefined;
    },
    setUserLocation: (
      state,
      action: PayloadAction<{ lat: number; lng: number }>
    ) => {
      state.userLocation = action.payload;
    },
    setResultsView: (state, action: PayloadAction<string>) => {
      state.resultsView = action.payload;
    },
    setUseUserLocation: (state, action: PayloadAction<boolean>) => {
      state.useUserLocation = action.payload;
    },
    removeUserLocation: (state) => {
      state.userLocation = undefined;
    },
    vendorImageUploaded: (
      state,
      action: PayloadAction<{
        image_url: string;
      }>
    ) => {
      const { image_url } = action.payload;
      state.vendorInProgress = {
        ...state.vendorInProgress,
        image_url,
      };
    },
  },
});

export const saveNewUser =
  (newUserData: UserInput, token?: string): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator(token);
      const response = await gqlClient.mutate({
        mutation: createUser,
        variables: {
          data: newUserData,
        },
      });
      dispatch(updateUser(response.data.createUser));
    } catch (e) {
      console.log(e);
    }
  };

export const getUserById =
  (uid: string): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getUserByIdQuery,
        variables: {
          uid,
        },
        // fetchPolicy: 'network-only',
      });

      dispatch(updateUser(response.data.getUserById));
    } catch (e) {
      console.log(e);
    }
  };

export const getUserByIdNetworkOnly =
  (uid: string): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getUserByIdQuery,
        variables: {
          uid,
        },
        fetchPolicy: 'network-only',
      });

      dispatch(updateUser(response.data.getUserById));
    } catch (e) {
      console.log(e);
    }
  };

export const updateUserAfterSocialSignIn =
  (usrData: UserInput): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getUserByIdQuery,
        variables: {
          uid: usrData.uid,
        },
      });
      const foundUser: User = response.data.getUserById;

      if (foundUser) {
        dispatch(updateUser(foundUser));
      } else {
        dispatch(saveNewUser(usrData));
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getFavorites =
  (uid: string): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getFavoritesQuery,
        variables: {
          uid,
        },
        fetchPolicy: 'network-only',
      });
      dispatch(updateFavorites(response.data.getFavorites));
    } catch (e) {
      console.log(e);
    }
  };

export const userExists =
  (uid: string): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: userExistsQuery,
        variables: {
          uid,
        },
        fetchPolicy: 'network-only',
      });
      dispatch(updateFavorites(response.data.userExists));
    } catch (e) {
      console.log(e);
    }
  };

export const removeLike =
  (data: LikeInput): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.mutate({
        mutation: removeLikeMutation,
        variables: {
          data,
        },
      });
      dispatch(updateFavorites(response.data.removeLike));
    } catch (error) {
      console.log(error);
    }
  };

export const addLike =
  (data: LikeInput): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.mutate({
        mutation: addLikeMutation,
        variables: {
          data,
        },
      });
      dispatch(updateFavorites(response.data.addLike));
    } catch (error) {
      console.log(error);
    }
  };

export const createReview =
  (data: ReviewInput): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: createReviewMutation,
        variables: {
          data,
        },
      });
      dispatch(getVendorDetails(data.vendor_id + ''));
    } catch (e) {
      console.log(e);
    }
  };

const blobToFile = (blob: Blob, fileName: string) => {
  return new File([blob], fileName, {
    type: blob.type,
  });
};

export const saveImageToCloud = async (
  blob: Blob,
  userId: string
): Promise<string> => {
  try {
    const filename = userId + new Date().getTime() + '.jpg';

    const file: File = blobToFile(blob, filename);

    const gqlClient = uploadClientCreator();
    await gqlClient.mutate({
      mutation: uploadVendorImageMutation,
      variables: {
        file,
      },
    });

    return filename;
  } catch (e) {
    return e.message;
  }
};

export const updateUserName =
  ({ display_name }: { display_name: string }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const uid = getState().users.current?.uid;
      if (!uid) return;

      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: updatePersonalProfileMutation,
        variables: {
          display_name,
        },
      });
      dispatch(getUserByIdNetworkOnly(uid));
    } catch (e) {
      console.log(e);
    }
  };

export const updateVendor =
  (data: any): AppThunk =>
  async (dispatch, getState) => {
    try {
      const uid = getState().users.current?.uid;
      if (!uid) return;
      const gqlClient = clientCreator();

      if (data.categoryId) {
        await gqlClient.mutate({
          mutation: updateVendorCategoryMutation,
          variables: {
            categoryId: data.categoryId,
          },
        });
      }

      const newData = {
        ...data,
      };

      delete newData.categoryId;

      for (const key of Object.keys(newData)) {
        if (!newData[key]) {
          newData[key] = '';
        }
      }

      await gqlClient.mutate({
        mutation: updateVendorNameAndDescriptionMutation,
        variables: {
          data: newData,
        },
      });

      dispatch(getUserByIdNetworkOnly(uid));
    } catch (e) {
      console.log(e);
    }
  };

export const updateVendorNameAndDescription =
  (data: UpdateVendorNameAndDescriptionInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const uid = getState().users.current?.uid;
      if (!uid) return;

      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: updateVendorNameAndDescriptionMutation,
        variables: {
          data,
        },
      });
      dispatch(getUserByIdNetworkOnly(uid));
    } catch (e) {
      console.log(e);
    }
  };

export const updateVendorLocation =
  (data: UpdateVendorLocationInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const uid = getState().users.current?.uid;
      if (!uid) return;

      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: updateVendorLocationMutation,
        variables: {
          data,
        },
      });
      dispatch(getUserByIdNetworkOnly(uid));
    } catch (e) {
      console.log(e);
    }
  };

export const updateVendorLanguages =
  (data: UpdateVendorLanguagesInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const uid = getState().users.current?.uid;
      if (!uid) return;

      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: updateVendorLanguagesMutation,
        variables: {
          data,
        },
      });
      dispatch(getUserByIdNetworkOnly(uid));
    } catch (e) {
      console.log(e);
    }
  };

export const updateVendorCountry =
  (data: UpdateVendorCountryInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const uid = getState().users.current?.uid;
      if (!uid) return;

      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: updateVendorCountryMutation,
        variables: {
          data,
        },
      });
      dispatch(getUserByIdNetworkOnly(uid));
    } catch (e) {
      console.log(e);
    }
  };

export const updateVendorImage =
  (data: UpdateVendorImageInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const uid = getState().users.current?.uid;
      if (!uid) return;

      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: updateVendorImageMutation,
        variables: {
          data,
        },
      });
      dispatch(getUserByIdNetworkOnly(uid));
    } catch (e) {
      console.log(e);
    }
  };

export const createVendor =
  (data: any): AppThunk =>
  async (dispatch, getState) => {
    try {
      const uid = getState().users.current?.uid;
      if (!uid) return;

      const newData = {
        ...data,
      };

      for (const key of Object.keys(newData)) {
        if (!newData[key]) {
          newData[key] = '';
        }
      }

      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: createVendorMutation,
        variables: {
          data: newData,
        },
      });

      dispatch(getUserByIdNetworkOnly(uid));
    } catch (e) {
      console.log(e);
    }
  };

export const sendEmail =
  (data: SendEmailInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: sendEmailMutation,
        variables: {
          data,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const sendWelcomeEmail =
  (data: WelcomeEmailInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: sendWelcomeEmailMutation,
        variables: {
          data,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const sendEmailToVendor =
  (data: SendEmailToVendorInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: sendEmailToVendorMutation,
        variables: {
          data,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

// export const createStripeSubscription =
//   (paymentMethodId: string, plan: string): AppThunk =>
//   async (dispatch, getState) => {
//     try {
//       const uid = getState().users.current?.uid;
//       if (!uid) return;

//       const gqlClient = clientCreator();
//       const res = await gqlClient.mutate({
//         mutation: createStripeSubscriptionMutation,
//         variables: {
//           paymentMethodId,
//           plan,
//         },
//       });
//       dispatch(getUserByIdNetworkOnly(uid));
//       if (res.data.createStripeSubscription) {
//         dispatch(paymentSuccess({ paymentSuccess: true }));
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };

// export const cancelStripeSubscription =
//   (): AppThunk => async (dispatch, getState) => {
//     try {
//       const uid = getState().users.current?.uid;
//       if (!uid) return;
//       const gqlClient = clientCreator();
//       await gqlClient.mutate({
//         mutation: cancelStripeSubscriptionMutation,
//         variables: {},
//       });
//       dispatch(getUserByIdNetworkOnly(uid));
//     } catch (e) {
//       console.log(e);
//     }
//   };

// export const createPaypalSubscription =
//   (subscriptionId: string): AppThunk =>
//   async (dispatch, getState) => {
//     try {
//       const uid = getState().users.current?.uid;
//       const plan = getState().users.vendorInProgress?.plan;
//       if (!uid) return;
//       const gqlClient = clientCreator();
//       var res = await gqlClient.mutate({
//         mutation: createPaypalSubscriptionMutation,
//         variables: {
//           subscriptionId,
//           plan,
//         },
//       });
//       if (res.data.createPaypalSubscription) {
//         dispatch(paymentSuccess({ paymentSuccess: true }));
//       }
//       dispatch(getUserByIdNetworkOnly(uid));
//     } catch (e) {
//       console.log(e);
//     }
//   };

export const {
  updateUser,
  signOut,
  updateFavorites,
  clearCurrentUser,
  setResultsView,
  setUserLocation,
  setUseUserLocation,
  removeUserLocation,
  // vendorCategorySelected,
  // vendorDescriptionUpdated,
  // vendorLocationSelected,
  // vendorLanguagesSelected,
  vendorImageUploaded,
  // paymentPlanFrequencySelected,
  // paymentSuccess,
  // paymentMethodSelected,
  // addDefaultLanguageToVendor,
  // updateVendorInProgress,
} = usersSlice.actions;

export const usersState = (state: RootState): UsersState => state.users;

export default usersSlice.reducer;

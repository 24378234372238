import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';

import config from '../config';
import firebase from 'firebase/app';
import { setContext } from '@apollo/link-context';

const cache = new InMemoryCache();

const link = createHttpLink({
  uri: config.apiPath,
  // credentials: 'include',
  credentials: config.apolloCredentials,
});

const createAuthLink = () => {
  const user = firebase.auth().currentUser;
  let token: string = 'none';
  if (user) {
    user.getIdToken().then((r) => {
      token = r;
    });
  }
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });
};

const createApolloClient = (
  token?: string
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createAuthLink().concat(link),
    cache,
  });
};

export default createApolloClient;

import type { AppThunk, RootState } from '../store';
import {
  AvailableCategoriesInput,
  Category,
  Vendor,
  VendorCategoryAndLocationInput,
  VendorSearchTextAndLocationInput,
  VendorsInLocationInput,
  VisitInput,
} from '../../generated/apolloComponents';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  addVisitMutation,
  getAvailableCategoriesQuery,
  getVendorDetailsQuery,
  getVendorsInLocationQuery,
  getVendorsMatchingCategoryAndLocationQuery,
  getVendorsMatchingSearchAndLocationQuery,
} from '../../graphql/vendors';

import clientCreator from '../../services/apolloClient';
import { getIdForCategoryFromCode } from '../../utils/business-categories';
import searchBoundingBox from '../../utils/searchBoundingBox';

export interface MatchingCategory {
  category: Partial<Category>;
  vendors: Vendor[];
}

export interface VendorWithSearchString extends Vendor {
  searchString: string;
}
export interface VendorsState {
  current?: Vendor;
  locationToSearchText?: string;
  locationToSearchLatLng?: { lat: number; lng: number };
  searchText?: string;
  matchingVendors: VendorWithSearchString[];
  searchRadius: number;
  businessCategory: string;
  searchStage?: 'location' | 'service';
  matchingCateories?: MatchingCategory[];
  selectedMatchingCategory?: MatchingCategory;
}

export const generateSearchStringForVendor = (vendor: Vendor) => {
  const {
    business_name,
    business_description,
    contact_email,
    contact_phone,
    website,
    instagram,
    street_address,
    sub_category,
    service_1,
    service_2,
    service_3,
    service_4,
    category,
    country,
  } = vendor;

  return [
    business_name,
    business_description,
    contact_email,
    contact_phone,
    website || null,
    instagram || null,
    street_address,
    sub_category || null,
    service_1 || null,
    service_2 || null,
    service_3 || null,
    service_4 || null,
    category.category_name,
    country.country_name,
  ]
    .map((s) => (s ? s.toLowerCase().trim() : null))
    .filter((s) => !!s)
    .join(' ');
};

export const vendorsInitialState: VendorsState = {
  matchingVendors: [],
  searchText: '',
  searchRadius: Math.round(20 / 0.621371),
  searchStage: 'location',
  businessCategory: 'all',
};

export const vendorsSlice = createSlice({
  name: 'vendors',
  initialState: vendorsInitialState,
  reducers: {
    updateVendor: (state, action: PayloadAction<Vendor>) => {
      state.current = action.payload;
    },
    setLocationToSearchText: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.locationToSearchText = action.payload;
    },
    setLocationToSearchLatLng: (
      state,
      action: PayloadAction<{ lat: number; lng: number } | undefined>
    ) => {
      state.locationToSearchLatLng = action.payload;
    },
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    updateSearchRadius: (state, action: PayloadAction<number>) => {
      state.searchRadius = action.payload;
    },
    updateBusinessCategory: (state, action: PayloadAction<string>) => {
      state.businessCategory = action.payload;
    },
    updateMatchingVendors: (state, action: PayloadAction<Vendor[]>) => {
      const vendorsWithSearchString = action.payload.map((vendor) => {
        return {
          ...vendor,
          searchString: generateSearchStringForVendor(vendor),
        };
      });

      state.matchingVendors = vendorsWithSearchString;
    },
    updateMatchingCategories: (
      state,
      action: PayloadAction<MatchingCategory[]>
    ) => {
      state.matchingCateories = action.payload;
    },
    updateSelectedMatchingCategory: (
      state,
      action: PayloadAction<MatchingCategory>
    ) => {
      state.selectedMatchingCategory = action.payload;
    },
    setSearchStage: (state, action: PayloadAction<'location' | 'service'>) => {
      state.searchStage = action.payload;
    },
  },
});

export const getVendorDetails =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getVendorDetailsQuery,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      });
      dispatch(updateVendor(response.data.getVendorDetails));
    } catch (e) {
      console.log(e);
    }
  };

export const getVendorsMatchingSearchAndLocation =
  (params: {
    searchText?: string;
    searchRadius: number;
    locationToSearchLatLng?: { lat: number; lng: number };
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { searchText, searchRadius, locationToSearchLatLng } = params;

      const country = getState().countries.selectedCountry;

      if (!searchText || !searchRadius || !locationToSearchLatLng || !country) {
        dispatch(updateMatchingVendors([]));
        return;
      }

      const boundingBox = searchBoundingBox(
        searchRadius,
        locationToSearchLatLng.lat,
        locationToSearchLatLng.lng
      );

      const searchParams: VendorSearchTextAndLocationInput = {
        ...boundingBox,
        country: country.country_code,
        searchText,
      };

      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getVendorsMatchingSearchAndLocationQuery,
        variables: { searchParams },
        fetchPolicy: 'network-only',
      });

      dispatch(
        updateMatchingVendors(response.data.getVendorsMatchingSearchAndLocation)
      );
    } catch (e) {
      console.log(e);
    }
  };

export const getAvailableCategories =
  (params: {
    countryCode: string;
    searchRadius: number;
    locationToSearchLatLng: { lat: number; lng: number };
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { searchRadius, locationToSearchLatLng, countryCode } = params;

      const boundingBox = searchBoundingBox(
        searchRadius,
        locationToSearchLatLng.lat,
        locationToSearchLatLng.lng
      );

      const searchParams: AvailableCategoriesInput = {
        ...boundingBox,
        countryCode,
      };

      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getAvailableCategoriesQuery,
        variables: { searchParams },
        fetchPolicy: 'network-only',
      });

      const results: Vendor[] = response.data.getAvailableCategories;

      let categories: MatchingCategory[] = [];

      for (let idx = 0; idx < results.length; idx++) {
        const vendor = results[idx];

        const existing = categories.find(
          (c) => c.category.id === vendor.category.id
        );
        if (existing) {
          existing.vendors = [...existing.vendors, vendor];
        } else {
          categories.push({
            category: vendor.category,
            vendors: [vendor],
          });
        }
      }

      // sort vendors in each category
      categories = categories.map((c) => {
        return {
          ...c,
          vendors: c.vendors
            .slice()
            .sort(
              (v1: Vendor, v2: Vendor) => v2.average_rating - v1.average_rating
            ),
        };
      });

      dispatch(updateMatchingCategories(categories));
    } catch (e) {
      console.log(e);
    }
  };

export const getVendorsMatchingCategoryAndLocation =
  (params: {
    businessCategory: string;
    countryCode: string;
    searchRadius: number;
    locationToSearchLatLng?: { lat: number; lng: number };
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        businessCategory,
        countryCode,
        searchRadius,
        locationToSearchLatLng,
      } = params;

      if (
        !businessCategory ||
        !searchRadius ||
        !locationToSearchLatLng ||
        !countryCode
      ) {
        dispatch(updateMatchingVendors([]));
        return;
      }

      const boundingBox = searchBoundingBox(
        searchRadius,
        locationToSearchLatLng.lat,
        locationToSearchLatLng.lng
      );

      const searchParams: VendorCategoryAndLocationInput = {
        ...boundingBox,
        countryCode,
        categoryId: getIdForCategoryFromCode(businessCategory),
      };

      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getVendorsMatchingCategoryAndLocationQuery,
        variables: { searchParams },
        fetchPolicy: 'network-only',
      });

      const sortedVendors = response.data.getVendorsMatchingCategoryAndLocation
        .slice()
        .sort(
          (v1: Vendor, v2: Vendor) => v2.average_rating - v1.average_rating
        );
      dispatch(updateMatchingVendors(sortedVendors));
    } catch (e) {
      console.log(e);
    }
  };

export const getVendorsInLocation =
  (params: {
    countryCode: string;
    searchRadius: number;
    locationToSearchLatLng?: { lat: number; lng: number };
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { countryCode, searchRadius, locationToSearchLatLng } = params;

      if (!searchRadius || !locationToSearchLatLng || !countryCode) {
        dispatch(updateMatchingVendors([]));
        return;
      }

      const boundingBox = searchBoundingBox(
        searchRadius,
        locationToSearchLatLng.lat,
        locationToSearchLatLng.lng
      );

      const searchParams: VendorsInLocationInput = {
        ...boundingBox,
        countryCode,
      };

      const gqlClient = clientCreator();
      const response = await gqlClient.query({
        query: getVendorsInLocationQuery,
        variables: { searchParams },
        fetchPolicy: 'network-only',
      });

      const sortedVendors = response.data.getVendorsInLocation
        .slice()
        .sort(
          (v1: Vendor, v2: Vendor) => v2.average_rating - v1.average_rating
        );
      dispatch(updateMatchingVendors(sortedVendors));
    } catch (e) {
      console.log(e);
    }
  };

export const recordVisit =
  (data: VisitInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: addVisitMutation,
        variables: {
          data,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const {
  updateVendor,
  setLocationToSearchText,
  updateSearchText,
  setLocationToSearchLatLng,
  updateMatchingVendors,
  updateSearchRadius,
  updateBusinessCategory,
  setSearchStage,
  updateMatchingCategories,
  updateSelectedMatchingCategory,
} = vendorsSlice.actions;

export const vendorsState = (state: RootState): VendorsState => state.vendors;

export default vendorsSlice.reducer;

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import config from '../config';
import { createUploadLink } from 'apollo-upload-client';

const cache = new InMemoryCache();

const link = createUploadLink({
  uri: config.apiPath,
  fetch,
  fetchOptions: { credentials: 'same-origin' },
});

const createApolloUploadClient = (
  token?: string
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: link,
    cache,
  });
};

export default createApolloUploadClient;

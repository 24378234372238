import backend from 'i18next-xhr-backend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // The default path is "../public/locales/en/translation.json"
  .use(backend)

  // passes i18n down to react-i18next
  .use(initReactI18next)

  .init({
    lng: 'en',

    // ... other configs
  });

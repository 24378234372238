const url = 'https://api.myecen.com/';
// const url = 'http://localhost:3001/';
// const url = 'https://apistage.myecen.com/';
const config = {
  appUrl: 'https://ecen.app/',
  apiPath: url + 'graphql',
  imageUploadPath: url + 'image-upload',
  imageUri1: url + 'image/',
  imageUri: 'https://d2d77clu3yo3p4.cloudfront.net/',
  languagePath: url + 'language/',
  googleAPIKey: 'AIzaSyBLErTNLH6Us4zV2c-8V5T0f8yk506-Y-U',
  cdnPath: 'https://d2d77clu3yo3p4.cloudfront.net/',
  apolloCredentials: 'same-origin',
  paypalClientId:
    'AeTQIi7nuUUOqzo32uWQ1O3TNXELdHFPxw8DmQn3uWK4cZSz9QAQx4ee5snM5o6w_qIT9d6R1dl2D8LW',
  paypalPlan: {
    monthly: 'P-0TT50415BD440592AMD6OHGY',
    annually: 'P-7FM50438XF022815RMD6OGJQ',
  },
  stripeKey:
    'pk_live_51IF1nmJQv27Ain8MXp9Q4i7TpIwSUtlcVjoVjq0mpxmvbecrzIpQpom2bVHHoPmpCLYgZtYmcyETKw4bd0j1Utvh003CSeja4C',
};

export default config;

import { gql } from '@apollo/client';

export const createUser = gql`
  mutation createUser($data: UserInput!) {
    createUser(data: $data) {
      uid
      email
      display_name
    }
  }
`;

export const getUserByIdQuery = gql`
  query getUserById($uid: String!) {
    getUserById(uid: $uid) {
      uid
      email
      display_name
      vendor {
        id
        active
        business_name
        business_description
        image_url
        contact_email
        contact_phone
        website
        instagram
        allow_email
        allow_messages
        number_reviews
        average_rating
        street_address
        latitude
        longitude
        service_1
        service_2
        service_3
        service_4
        sub_category
        payment_method
        welcome_sent
        category {
          id
          category_name
        }
        languages {
          code
          english_name
        }
      }
    }
  }
`;

export const userExistsQuery = gql`
  query userExists($uid: String!) {
    userExists(uid: $uid)
  }
`;

export const getFavoritesQuery = gql`
  query getFavorites($uid: String!) {
    getFavorites(uid: $uid) {
      id
      business_name
      business_description
      image_url
      contact_email
      contact_phone
      website
      instagram
      allow_email
      allow_messages
      number_reviews
      average_rating
      street_address
      latitude
      longitude
      category {
        category_name
      }
      country {
        country_code
        country_name
      }
    }
  }
`;

export const removeLikeMutation = gql`
  mutation removeLike($data: LikeInput!) {
    removeLike(data: $data) {
      id
      business_name
      business_description
      image_url
      contact_email
      contact_phone
      website
      instagram
      allow_email
      allow_messages
      number_reviews
      average_rating
      street_address
      latitude
      longitude
      category {
        category_name
      }
      country {
        country_code
        country_name
      }
    }
  }
`;

export const addLikeMutation = gql`
  mutation addLike($data: LikeInput!) {
    addLike(data: $data) {
      id
      business_name
      business_description
      image_url
      contact_email
      contact_phone
      website
      instagram
      allow_email
      allow_messages
      number_reviews
      average_rating
      street_address
      latitude
      longitude
      category {
        category_name
      }
      country {
        country_code
        country_name
      }
    }
  }
`;

export const createReviewMutation = gql`
  mutation createReview($data: ReviewInput!) {
    createReview(data: $data)
  }
`;

export const updatePersonalProfileMutation = gql`
  mutation updatePersonalProfile($display_name: String!) {
    updatePersonalProfile(display_name: $display_name)
  }
`;

export const sendEmailMutation = gql`
  mutation sendEmail($data: SendEmailInput!) {
    sendEmail(data: $data)
  }
`;

export const sendWelcomeEmailMutation = gql`
  mutation sendWelcomeEmail($data: WelcomeEmailInput!) {
    sendWelcomeEmail(data: $data)
  }
`;

export const sendEmailToVendorMutation = gql`
  mutation sendEmailToVendor($data: SendEmailToVendorInput!) {
    sendEmailToVendor(data: $data)
  }
`;

export const uploadVendorImageMutation = gql`
  mutation uploadVendorImage($file: Upload!) {
    uploadVendorImage(file: $file) {
      filename
      mimetype
      encoding
      url
    }
  }
`;

export const createStripeSubscriptionMutation = gql`
  mutation createStripeSubscription($paymentMethodId: String!, $plan: String!) {
    createStripeSubscription(paymentMethodId: $paymentMethodId, plan: $plan)
  }
`;

export const cancelStripeSubscriptionMutation = gql`
  mutation cancelStripeSubscription {
    cancelStripeSubscription
  }
`;

export const createPaypalSubscriptionMutation = gql`
  mutation createPaypalSubscription($subscriptionId: String!, $plan: String!) {
    createPaypalSubscription(subscriptionId: $subscriptionId, plan: $plan)
  }
`;

export const addPageVisitMutation = gql`
  mutation addPageVisit($data: PageVisitInput!) {
    addPageVisit(data: $data)
  }
`;

import type { AppThunk, RootState } from '../store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Country } from '../../generated/apolloComponents';
import { allCountries } from '../../graphql/countries';
import clientCreator from '../../services/apolloClient';

export interface CountriesState {
  countries?: Country[];
  selectedCountry?: Country | null;
  selectedVendorCountry?: Country | null;
}

export const countriesInitialState: CountriesState = {
  countries: [],
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState: countriesInitialState,
  reducers: {
    updateCountry: (state, action: PayloadAction<Country | null>) => {
      state.selectedCountry = action.payload;
    },
    updateVendorCountry: (state, action: PayloadAction<Country | null>) => {
      state.selectedVendorCountry = action.payload;
    },
    updateCountries: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
  },
});

export const getCountries = (): AppThunk => async (dispatch) => {
  try {
    const gqlClient = clientCreator();
    const response = await gqlClient.query({ query: allCountries });
    dispatch(updateCountries(response.data.allCountries));
  } catch (e) {
    console.log(e);
  }
};

export const { updateCountry, updateVendorCountry, updateCountries } =
  countriesSlice.actions;

export const countriesState = (state: RootState): CountriesState =>
  state.countries;

export default countriesSlice.reducer;

import type { AppThunk, RootState } from '../store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PageVisitInput } from '../../generated/apolloComponents';
import { addPageVisitMutation } from '../../graphql/users';
import clientCreator from '../../services/apolloClient';

export interface ModalInfo {
  title?: string;
  body?: string;
  buttonOneLabel?: string;
  buttonTwoLabel?: string;
  buttonOneAction?: 'sign-in';
  buttonTwoAction?: 'register';
}
export interface NavigationState {
  previousPage?: string;
  pageAfterRedirect?: string;
  showModal: boolean;
  showHowToSaveModal: boolean;
  showShareDrawer: boolean;
  showSettingsDrawer: boolean;
  showVendorCategoryFilterDrawer: boolean;
  modalData: ModalInfo;
  geolocationPossible: boolean;
  geolocationEnabled: boolean;
  activeSearchTab: number;
  activeMessagesTab: number;
  signUpFlow?: boolean;
  IP: string;
}

export const navigationInitialState: NavigationState = {
  showModal: false,
  showHowToSaveModal: false,
  showShareDrawer: false,
  showSettingsDrawer: false,
  showVendorCategoryFilterDrawer: false,
  modalData: {},
  geolocationPossible: false,
  geolocationEnabled: false,
  activeSearchTab: 0,
  activeMessagesTab: 0,
  signUpFlow: false,
  IP: '',
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: navigationInitialState,
  reducers: {
    updatePreviousPage: (state, action: PayloadAction<string>) => {
      state.previousPage = action.payload;
    },
    pageAfterRedirectUpdate: (state, action: PayloadAction<string>) => {
      state.pageAfterRedirect = action.payload;
    },
    toggleModal: (state, action: PayloadAction<string>) => {
      state.showModal = action.payload === 'on' ? true : false;
    },
    toggleHowToSaveModal: (state, action: PayloadAction<string>) => {
      state.showHowToSaveModal = action.payload === 'on' ? true : false;
    },
    toggleShareDrawer: (state, action: PayloadAction<string>) => {
      state.showShareDrawer = action.payload === 'on' ? true : false;
    },
    toggleSettingsDrawer: (state) => {
      state.showSettingsDrawer = !state.showSettingsDrawer;
    },
    toggleVendorCategoryFilterDrawer: (state) => {
      state.showVendorCategoryFilterDrawer =
        !state.showVendorCategoryFilterDrawer;
    },
    setModalData: (state, action: PayloadAction<ModalInfo>) => {
      state.modalData = action.payload;
    },
    setGeolocationPossible: (state, action: PayloadAction<boolean>) => {
      state.geolocationPossible = action.payload;
    },
    setGeolocationEnabled: (state, action: PayloadAction<boolean>) => {
      state.geolocationEnabled = action.payload;
    },
    setActiveSearchTab: (state, action: PayloadAction<number>) => {
      state.activeSearchTab = action.payload;
    },
    setActiveMessagesTab: (state, action: PayloadAction<number>) => {
      state.activeMessagesTab = action.payload;
    },
    setSignUpFlow: (state, action: PayloadAction<boolean>) => {
      state.signUpFlow = action.payload;
    },
    noteIP: (state, action: PayloadAction<string>) => {
      state.IP = action.payload;
    },
  },
});

export const logPageVisit =
  (data: PageVisitInput): AppThunk =>
  async (dispatch, getState) => {
    try {
      const gqlClient = clientCreator();
      await gqlClient.mutate({
        mutation: addPageVisitMutation,
        variables: {
          data,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

export const {
  updatePreviousPage,
  toggleModal,
  toggleHowToSaveModal,
  toggleShareDrawer,
  toggleSettingsDrawer,
  pageAfterRedirectUpdate,
  setModalData,
  setGeolocationPossible,
  setGeolocationEnabled,
  setActiveSearchTab,
  toggleVendorCategoryFilterDrawer,
  setActiveMessagesTab,
  setSignUpFlow,
  noteIP,
} = navigationSlice.actions;

export const navigationState = (state: RootState): NavigationState =>
  state.navigation;

export default navigationSlice.reducer;
